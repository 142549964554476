<template>
  <div class="form">
    <form  ref="form" @submit.prevent="sendEmail">
      <h1>Napisz coś</h1>
      <div class="input__group">
        <input required
          :class="{invalid: nameValidity === 'invalid'}"
          v-model.trim="from_name" 
          type="text" 
          name="from_name" 
          placeholder="Imię i Nazwisko *"
          @blur="validateInputName">
        <p v-if="nameValidity === 'invalid'">to pole nie może być puste</p>
      </div>
      <div class="input__group">
        <input 
          v-model="company" 
          type="text" 
          name="company"  
          placeholder="Firma">
      </div>
      <div class="input__group">
        <input required
          :class="{invalid: mailValidity === 'invalid'}"
          v-model="from_email" 
          type="from_email" 
          name="from_email"  
          placeholder="Email *"
          @blur="validateInputMail">
        <p v-if="mailValidity === 'invalid'">to pole nie może być puste</p>
      </div>
      <div class="input__group">
        <textarea required
          :class="{invalid: contentValidity === 'invalid'}"
          v-model="mailContent" 
          name="mailContent" 
          placeholder="Opis *"
          @blur="validateInputContent">></textarea>
        <p v-if="contentValidity === 'invalid'">to pole nie może być puste</p>
      </div>
      <div class="input__group__CheckBox">
        <input 
          class="checkbox__input" 
          type="checkbox"
          name="myCheckboxName" 
          required/>
          <h1>
            * Wyrażam dobrowolną zgodę na przetwarzanie przez I-Instal Sp.z o.o. z siedzibą w
            Wejherowie przy ulicy Sobieskiego 328 d/5, moich danych osobowych zawartych w
            tym formularzu w celu i zakresie niezbędnym do odpowiedzi na to pytanie.
            Potwierdzam zapoznanie się z <a download href="Rodo.docx">polityką prywatności.</a> 
          </h1>
      </div>
      <div class="input__group">
        <button type="submit" value="Send" required>Wyślij</button>
      </div>
      <div class="loading__container">
        <the-loading
          v-if="waiting"
          :color="'rgb(211, 59, 69)'"
          :color2="'rgb(211, 59, 69)'"
        ></the-loading>
      </div>
    </form>
    
  </div>
</template>

<script>
import TheLoading from './TheLoading.vue'
import emailjs from '@emailjs/browser';
export default {
  components:{
    TheLoading,
  },
  data(){
    return{
      from_name: null,
      company: null,
      from_email: null,
      mailContent: null,
      mailSuccess: false,
      sendValidity: '',
      waiting: false,
      
      nameValidity: 'pending',
      mailValidity: 'pending',
      contentValidity: 'pending',
    };
  },
  methods:{
    cleanInputs(){
      this.from_name = '';
      this.company= '';
      this.from_email = '';
      this.mailContent = '';
    },
    sendEmail() {
      this.waiting = true;
      this.cleanInputs();

      emailjs.sendForm('Informatyk1', 'template_cbefmjk', this.$refs.form, 'd_4z_Zpr1-3s9lpTd',{
        company: this.company,
        from_name: this.from_name,
        from_email: this.from_email,
        emailContent: this.emailContent
      })
        .then((result) => {
            console.log('SUCCESS!', result.text);
            this.sendValidity = 'success'
            this.waiting = false;
        }, (error) => {
            console.log('FAILED...', error.text);
            this.sendValidity = 'failed'
            this.waiting = false;
        });
    },
    validateInputName(){
      if(this.from_name === ''){
        this.nameValidity = 'invalid';
      }else{
        this.nameValidity = 'valid';
      }
    },
    validateInputMail(){
      if(this.from_email === ''){
        this.mailValidity = 'invalid';
      }else{
        this.mailValidity = 'valid';
      }
    },
    validateInputContent(){
      if(this.mailContent === ''){
        this.contentValidity = 'invalid';
      }else{
        this.contentValidity = 'valid';
      }
    },
  },
}
</script>

<style scoped>
.form{
  padding: 3rem;
  background-color: white;
}
.form h1{
  font-size: 1.5rem;
}
.input__group{
  position: relative;
}
.input__group p {
  font-size: 0.7rem;
  transition: 0.3s all;
  padding: 0.2rem 0.5rem;
  color: red;
}
.invalid{
  border-bottom: 1px red solid;
}
.input__group,h1{
  margin: 1rem 1rem;
}
input,textarea{
  border: none;
  border-bottom: 1px rgb(211, 59, 69) solid;
  padding: 0 0.5rem;
  transition: 0.3s all;
}
input:focus,
textarea:focus{
  outline: none;
  border-bottom: 1px rgb(211, 59, 69) solid;
  transform: translateX(1.5%)
}
input,textarea,button{
  height: 2.2rem;
  width: 100%;
}
textarea{
  max-width: 450px;
  max-height: 200px;
  min-height: 4rem;
  padding: 0.5rem 0.5rem;
}
button{
  background-color: rgb(211, 59, 69);
  border: none;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  height: 3rem;
  transition: 0.3s all;
}
button:hover{
  background-color: rgb(85, 10, 45);
}
label{
  position: absolute;
  left: 0.5rem;
  top: 0.6rem;
  font-size: 0.8rem;
}
.input__group__CheckBox{
  display: flex;
  align-items: flex-start;
  justify-content: left;
  max-width: 400px;
  margin: 1rem 1rem;
}
.input__group__CheckBox a{
  color: rgb(211, 59, 69);
}
.input__group__CheckBox input{
  width: unset;
  height: unset;
  box-shadow: unset;
  cursor: pointer;

}
.input__group__CheckBox input{
  background-color: rgb(211, 59, 69);
}
.input__group__CheckBox h1{
  margin: 0 1rem;
  color: rgb(179, 179, 179);
  font-size: 0.6rem;
}
.loading__container{
  margin: 0;
  align-self: center;
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
}
@media screen and (max-width:800px){
  .form{
  padding: 1rem;
}
}
</style>