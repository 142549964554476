<template>
  <div class="photo__box" :style="{ backgroundImage: path }">
    <font-awesome-icon
      class="glass"
      icon="fa-solid fa-magnifying-glass"
      @click="zoomIn($event.target)"
    />
    <font-awesome-icon
      class="x"
      icon="fa-solid fa-x"
      @click="zoomOff($event.target)"
    />
  </div>
</template>

<script>
export default {
  props: ['path'],
  methods: {
    zoomIn(el) {
      el.parentElement.classList.add('big');
      const x = el.nextElementSibling;
      x.style.display = 'block';
    },
    zoomOff(el) {
      el.parentElement.classList.remove('big');
      el.style.display = 'none';
    },
  },
};
</script>

<style scoped>
.x:hover,
.glass:hover {
  scale: 1.1;
  opacity: 1;
}
.x {
  position: absolute;
  font-size: 1rem;
  background-color: #d33b45;
  color: white;
  padding: 0.5rem;
  border-radius: 50%;
  aspect-ratio: 1/1;
  top: 1rem;
  right: 1rem;
  transition: 0.3s all;
  display: none;
  opacity: 0.7;
}
.glass {
  position: absolute;
  font-size: 1rem;
  background-color: #d33b45;
  color: white;
  padding: 0.5rem;
  border-radius: 50%;
  aspect-ratio: 1/1;
  bottom: 1rem;
  right: 1rem;
  transition: 0.3s all;
  opacity: 0.7;
}
.photo__box {
  width: 30%;
  min-width: 400px;
  height: 500px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 2rem;
  transition: 0.3s all;
  cursor: pointer;
  filter: drop-shadow(20px 30px 30px rgba(0, 0, 0, 0.25));
}
.big {
  position: fixed;
  top: 5%;
  left: 5%;
  width: 90%;
  height: 90%;
  z-index: 20000;
}
.big:hover {
  scale: 1;
}
</style>
<style>
path {
  pointer-events: none;
}
@media screen and (max-width: 600px) {
  .photo__box {
    width: 95% !important;
    min-width: unset !important;
  }
  .big {
    position: unset !important;
    top: unset !important;
    left: unset !important;
    width: 100% !important;
    height: 500px !important;
  }

}
</style>
