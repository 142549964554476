<template>
  <div class="realizacje__section">
    <div class="textBox">
      <h1>Nasze realizacje</h1>
      <div class="line"></div>
    </div>
    <div class="photos__container">
      <transition-group appear @before-enter="beforeEnterRight" @enter="enter">
        <the-photo
          v-for="id in 77"
          :key="id"
          :path="`url('assets/realizacje/${id}.png')`"
        ></the-photo>
      </transition-group>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);
import ThePhoto from '../ThePhoto.vue';
export default {
  components: {
    ThePhoto,
  },
  methods: {
    beforeEnter(el) {
      el.style.opacity = 0;
      el.style.transform = 'translateX(-100%)';
    },
    beforeEnterRight(el) {
      el.style.opacity = 0;
      el.style.transform = 'translateX(100%)';
    },
    enter(el) {
      gsap.to(el, {
        scrollTrigger: {
          trigger: el,
          start: 'center, 80%',
        },
        opacity: 1,
        x: 0,
        duration: 0.2,
      });
    },
  },
};
</script>

<style scoped>
img {
  max-width: 100%;
  max-height: 500px;
  margin: 1%;
}
.realizacje__section {
  width: 100%;
  height: auto;
  overflow: hidden;
}
.textBox {
  color: white;
  width: 50%;
  padding: 1rem 2rem;
  margin: 3rem 1rem;
  position: relative;
  z-index: 10;
}
.textBox::after {
  z-index: -1;
  position: absolute;
  left: -10%;
  top: 0%;
  content: '';
  width: 50%;
  height: 200%;
  background-color: rgba(136, 136, 136, 0.39);
  transition: width 0.3s;
}
.line {
  width: 100px;
  height: 8px;
  margin: 2rem 0;
  background-color: white;
  position: relative;
}
h1 {
  font-weight: 600;
  font-size: 2.5rem;
  z-index: 22;
}
.photos__container {
  position: relative;
  padding: 0 5%;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  z-index: 21;
  padding: 0 0.5rem;
  padding-bottom: 5%;
}
@media screen and (max-width: 1200px) {
  .textBox::before {
    width: 70%;
  }
  .photos__container {
    padding: 0 3%;
  }
  .line {
    margin: 1rem 0;
  }
  h1 {
    font-size: 2rem;
  }
}
@media screen and (max-width: 800px) {
  .textBox {
    color: white;
    width: 80%;
    padding: 0.6rem 1rem;
    margin: 1.5rem 1rem;
    position: relative;
    z-index: 10;
  }
  .textBox::before {
    width: 70%;
  }
  .photos__container {
    padding: 0 3%;
  }
  .line {
    height: 2px;
    margin: 1rem 0;
    width: 50px;
  }
  h1 {
    font-size: 1.3rem;
  }
}
</style>
