<template>
  <div>
    <div class="main__Section">
      <carousel :items-to-show="1" :autoplay=5000>
        <slide v-for="button in buttons" :key="button.id">
          <home-section
            :h1="button.h1"
            :h2="button.h2"
            :imgSrc="button.imgSrc"
            :link="button.link"
          ></home-section>
        </slide>

        <template #addons>
          <pagination />
        </template>
      </carousel>
      <!-- <home-section
        v-if="selectedBtn.id === 1"
        :h1="selectedBtn.h1"
        :h2="selectedBtn.h2"
        :imgSrc="selectedBtn.imgSrc"
        :link="selectedBtn.link"
      ></home-section>
      <home-section
        v-else-if="selectedBtn.id === 2"
        :h1="selectedBtn.h1"
        :h2="selectedBtn.h2"
        :imgSrc="selectedBtn.imgSrc"
        :link="selectedBtn.link"
      ></home-section>
      <home-section
        v-else-if="selectedBtn.id === 3"
        :h1="selectedBtn.h1"
        :h2="selectedBtn.h2"
        :imgSrc="selectedBtn.imgSrc"
        :link="selectedBtn.link"
      ></home-section>
      <home-section
        v-else-if="selectedBtn.id === 4"
        :h1="selectedBtn.h1"
        :h2="selectedBtn.h2"
        :imgSrc="selectedBtn.imgSrc"
        :link="selectedBtn.link"
      ></home-section> -->
    </div>
    <div class="footer">
      <the-footer></the-footer>
    </div>
  </div>
</template>

<script>
import HomeSection from './homeSection/homeSection.vue';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination } from 'vue3-carousel';
/* import PartnerSection from './homeSection/partnersSection.vue'; */
export default {
  components:{
    HomeSection,
    Carousel,
    Slide,
    Pagination,
  },
  data() {
    return {
      buttons: [
        {
          id: 1,
          h1: 'Fotowoltaika',
          h2: 'Pędzące ceny prądu powodują , ze ludzie co raz częściej szukają nowych rozwiązań na dostarczenie energii do swojego domu – panele sa w tym przypadku świetnym rozwiązaniem . ',
          imgSrc: '/assets/home/fotowoltaik.png',
          link: '#box1'
        },
        {
          id: 2,
          h1: 'Instalacje elektryczne',
          h2: 'Zajmujemy się wykonywaniem instalacji elektrycznych w mieszkaniach prywatnych i deweloperskich, domach jednorodzinnych, hotelach, obiektach przemysłowych i handlowych oraz w różnego rodzaju instytucjach.',
          imgSrc: '/assets/home/electric.png',
          link: '#box2'
        },
        {
          id: 3,
          h1: 'Monitoring CCTV',
          h2: 'Wykonujemy instalacje video monitoringu na zlecenie. Oferujemy wsparcie techniczne na etapie dokonywania wyboru konkretnych rozwiązań i systemów. ',
          imgSrc: '/assets/Home/cctv.png',
          link: '#box3'
        },
        {
          id: 4,
          h1: 'Alarmy',
          h2: 'System alarmowy to zaawansowana technologia zabezbieczenia obiektu ubrana w bardzo prostą obsługę.',
          imgSrc: '/assets/home/alarm.png',
          link: '#box4'
        },
      ],
    };
  },
};
</script>
<style scoped>

.main__Section{
  position: relative;
  overflow: hidden;
  padding: 1% 0;
}
.main__Section__Nav{
  overflow: visible;
  position: absolute;
  bottom: 50px;
  left: calc(50% - 60px);
  display: flex;
  align-content: center;
  justify-content: space-around;
  width: 120px;
  height: 20px;
}
.partners__Section{
  background-color: white;
}

</style>