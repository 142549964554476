<template>
  <div class="box">
    <div class="textBox">
      <h1>{{ heading }}</h1>
      <div class="line"></div>
      <div class="h2" v-html="content"></div>
    </div>
    <div class="imgBox">
      <img :src="'assets/Home/' + photo" alt="" />
    </div>
    <h1 class="headingBg">{{ headingBg }}</h1>
    <div class="card">
      <a :href="'callto:' + phone">
        <div class="email">
          <font-awesome-icon icon="fa-solid fa-mobile-screen-button" />
          <h1>{{ phone }}</h1>
        </div>
      </a>
      <a :href="'mailto:' + mail">
        <div class="phone">
          <font-awesome-icon icon="fa-solid fa-envelope" />
          <h1>{{ mail }}</h1>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: ['heading', 'content', 'bg', 'photo', 'phone', 'mail', 'headingBg'],
  data() {
    return {};
  },
  methods: {
    openText(el) {
      console.log(el);
      el.classList.toggle('arrowRotate');
    },
  },
};
</script>
<style>
ul {
  list-style: square;
}
span {
  font-weight: bold;
  color: rgb(255, 255, 255);
}
</style>
<style scoped>
a {
  text-decoration: none;
  color: inherit;
  transition: 0.3s color;
}
a:hover {
  color: rgba(255, 255, 255, 0.7);
}
.card {
  position: absolute;
  left: 5%;
  bottom: 5%;
  width: 50%;
  padding: 20px 0;
}
.card::before {
  content: '';
  position: absolute;
  left: -25%;
  bottom: 10%;
  width: 70%;
  height: 6px;
  border-radius: 5px;
  background-color: white;
}
.email svg,
.phone svg {
  margin-right: 10px;
  align-self: center;
}
.email,
.phone {
  display: flex;
  align-content: center;
  justify-content: left;
  margin: 10px 0;
}
.card,
.card h1 {
  font-size: 1.2rem;
  font-weight: 400;
}
.box {
  position: relative;
  width: 100%;
  height: calc(100vh - 141px);
  padding: 2rem;
  scroll-snap-align: start;
  transition: 0.4s all;
  display: flex;
  align-items: center;
  justify-content: left;
  overflow-x: hidden;
}
.box::before {
  z-index: 0;
  position: absolute;
  left: 0;
  top: 20%;
  content: '';
  width: 30%;
  height: 50%;
  background-color: rgba(136, 136, 136, 0.39);
}
.headingBg {
  position: absolute;
  left: -0%;
  bottom: 22%;
  font-size: 15rem;
  font-weight: bolder;
  color: rgba(0, 0, 0, 0.1);
  width: 150%;
  z-index: 1;
}
.textBox {
  color: white;
  width: 50%;
  padding: 1rem 2rem;
  margin: 3rem 1rem;
  position: relative;
  z-index: 10;
}
.imgBox {
  position: absolute;
  right: 7%;
  width: 40%;
  max-width: 600px;
  z-index: 2;
}
.imgBox img {
  width: 100%;
}
.line {
  width: 100px;
  height: 8px;
  margin: 2rem 0;
  background-color: white;
  position: relative;
}
h1 {
  font-weight: 600;
  font-size: 2.5rem;
}
.h2 {
  position: relative;
  font-weight: 300;
  font-size: 1.2rem;
  max-height: 300px;
  overflow: scroll;
  position: relative;
  transition: 0.3s all;
  padding-right: 1rem;
}
.h2::before{
  content:'';
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  height: 50px;
}
*::-webkit-scrollbar {
  width: 0.4vw !important;
}
*::-webkit-scrollbar-thumb {
  background-color: #d33b45;
}
*::-webkit-scrollbar-track {
  border-left: none !important;
}
.textOpen {
  height: 100%;
  color: white;
  font-weight: 300 !important;
}
@media screen and (max-width: 1180px) {
  .textBox {
    width: 60%;
  }
  .box {
    height: calc(100vh - 104px);
  }
  h1 {
    font-weight: 500;
    font-size: 2rem;
  }
  .h2 {
    font-weight: 400;
    font-size: 1rem;
  }
  .card::before {
    width: 80%;
  }
}
@media screen and (max-width: 880px) {
  .box {
    height: calc(100vh - 104px);
    padding: 0.7rem;
  }
  .arrow {
    bottom: -60px;
  }
  .textBox {
    width: 80%;
  }
  h1 {
    font-weight: 500;
    font-size: 2rem;
  }
  .h2 {
    font-weight: 300;
    font-size: 0.9rem;
  }
  .textBox::before {
    width: 0.3rem;
  }
  .box::before {
    width: 60%;
    height: 55%;
    z-index: 1;
    background-color: rgba(71, 71, 71, 0.95);
  }
  .card::before {
    width: 80%;
  }
  .card {
    width: 70%;
  }
  a,
  .card h1 {
    font-size: 1rem;
  }
  .imgBox img {
    filter: grayscale(0.5);
  }
  .imgBox {
    position: absolute;
    top: 0;
    right: 0;
    width: 80%;
    z-index: 0;
  }
  .line {
    height: 4px;
    width: 50px;
  }
}
@media screen and (max-width: 480px) {
  .card {
    width: 120%;
  }
  .arrow {
    bottom: -40px;
  }
  .box::before {
    width: 80%;
    height: 65%;
    top: 5%;
  }
  .textBox {
    width: 80%;
    padding: 0rem;
    margin: 0.5rem;
  }
  .box {
    align-items: flex-start;
  }
  .card {
    bottom: 5%;
  }
  .line{
    margin: 1rem 0;
    height: 3px;
  }
  .h2{
    font-size: 0.8rem;
  }
}
*::-webkit-scrollbar {
  width: 1vw;
}
@media screen and (max-width: 400px) {
  .textBox {
    width: 80%;
    padding: 0rem;
    margin: 0.5rem;
  }
  .line{
    margin: 0.5rem 0;
    height: 2px;
  }
  h1{
    font-size: 1.1rem;
  }
  .h2{
    font-size: 0.7rem;
    max-height: 350px;
    overflow: scroll;
  }
  .card {
    bottom: 1%;
  }
}
</style>
