<template>
  <div>
    <div class="oferta__Section">
      <single-oferta
        :id="'box1'"
        :phone="users[1].phone"
        :mail="users[1].email"
        :photo="'fotowoltaik.png'"
        :heading="'Fotowoltaika'"
        :headingBg="'Fotowoltaika'"
        :content="'Fotowoltaika będzie doskonałym rozwiązaniem dla każdego kto chciałby przyczynić się do poprawy stanu środowiska naturalnego jak i dla każdego kto chce zaoszczedzić na rachunkach za prąd . Pędzące ceny prądu powodują , że ludzie co raz częściej szukają nowych rozwiazań na dostarczenie energii do swojego domu – panele sa w tym przypadku świetnym rozwiązaniem . Realizacje projektu przeprowadzamy kompleksowo – od momentu zgłoszenia po załatwienie formalności z dostawcą energii . Pomagamy przy wyborze najkorzystniejszego rozwiązania oraz wskazujemy wszystkie możliowści  finansowania <br><br><span>Dzięki skrupulatnej analizie, którą przeprowadzimy, stworzymy rozwiązanie szyte na miarę i nieprzeskalowane. Dla nas liczy się zadowolony klient.</span>'"
      ></single-oferta>
      <single-oferta
        :id="'box2'"
        :phone="users[1].phone"
        :mail="users[1].email"
        :photo="'electric.png'"
        :heading="'Instalacje elektryczne'"
        :headingBg="'Elektryczne'"
        :content="
          '<ul><li>- Rozdzielnice elektryczne</li><li>- Agregaty prądotwórcze</li><li>- Zasilacze awaryjne UPS</li><li>- Instalacje zasilania awaryjnego</li><li>- Instalacje oświetlenia zewnętrznego i wewnętrznego</li><li>- Rozdzielnice elektryczne</li><li>- Instalacje odgromowe</li><li>- Instalacje siły i gniazd wtyczkowych</li><li>- Instalacje sterownicze i sygnalizacji</li><li>- Instalacje zasilania awaryjnego</li><li>- Zasilanie urządzeń technologicznych</li><li>- Trasy kablowe</li><li>- Serwis</li></ul><br>W naszej firmie stawiamy przede wszystkim na bezpieczeństwo, funkcjonalność i estetykę wykonania. Pracujemy z wykorzystaniem wyłącznie najwyższej jakości materiałów, co ułatwia ewentualną modernizację w przyszłości, na etapie kolejnego remontu. Nasi pracownicy zajmują się umiejscowieniem całej infrastruktury. Zapraszamy do skorzystania z naszych usług zarówno klientów indywidualnych, jak i biznesowych. Zajmujemy się  wykonywaniem instalacji elektrycznych w mieszkaniach prywatnych i deweloperskich, domach jednorodzinnych, hotelach, obiektach przemysłowych i handlowych oraz w różnego rodzaju instytucjach.'"
      ></single-oferta>
      <single-oferta
        :phone="users[2].phone"
        :mail="users[2].email"
        :id="'box3'"
        :photo="'cctv.png'"
        :heading="'Monitoring CCTV'"
        :headingBg="'CCTV'"
        :content="'Kamery do monitoringu to jeden z najważniejszych elementów systemu dozoru wizyjnego. Istotna jest przede wszystkim ich jakość oraz dopasowanie do konkretnego typu zastosowania. W połączeniu z odpowiednim rozmieszczeniem pozwoli to skutecznie zabezpieczyć obiekt.<br><br> Jak wybrać kamery do monitoringu?<br><br> Parametry wybranych kamer do monitoringu powinny być zgodne nie tylko z warunkami, w których będą pracować, ale również z technologią obsługiwaną przez rejestrator do monitoringu. Obecnie na rynku dostępne są kamery do monitoringu pracujące w technologiach AHD, HD-CVI, HD-TVI i Kamery IP – możesz więc dopasować odpowiednie urządzenia do każdego systemu. Monitoring pozwala przywrócić poczucie bezpieczeństwa mieszkańcom oraz przejąć pełną kontrolę nad funkcjonowaniem firmy pracodawcom.<br><br> Wykonujemy instalacje video monitoringu na zlecenie. Oferujemy wsparcie techniczne na etapie dokonywania wyboru konkretnych rozwiązań i systemów. Nasi pracownicy posiadają rozległą wiedzę na temat dostępnych możliwości, dlatego sprawnie zamontują każdy rodzaj monitoringu.'"
      ></single-oferta>
      <single-oferta
        :phone="users[2].phone"
        :mail="users[2].email"
        :id="'box4'"
        :photo="'alarm.png'"
        :heading="'Alarmy'"
        :headingBg="'Alarmy'"
        :content="'SSWiN (System Sygnalizacji Włamania i Napadu) to elektroniczny zaawansowany system stosowany w domach jak i obiektach komercyjnych, ubrany w bardzo prostą obsługę z poziomu manipulatora jak i telefonu, po przez dedykowaną aplikację. Podstawowa instalacja alarmowa jest tanią formą zabezpieczenia naszego mienia w trakcie naszej nieobecności, ale także w bardziej zaawansowanej formie chroni życie i zdrowie osób znajdujących się w danym obiekcie, poprzez szereg różnych czujników wykrywających dym, gazy łatwopalne czy nawet usypiające. Centrale SSWiN umożliwiają również stworzenie automatyki budynkowej począwszy od sterowania prostymi urządzeniami takimi jak bramy wjazdowe czy rolety, kończąc na dużej i zaawansowanej automatyce. Każda instalacja kończy się przeszkoleniem użytkownika po którym obsługa systemu staje się prosta i intuicyjna.'"
      ></single-oferta>
      <single-oferta
        :phone="users[2].phone"
        :mail="users[2].email"
        :id="'box5'"
        :photo="'automatic.png'"
        :heading="'Automatyka'"
        :headingBg="'Automatyka'"
        :content="'W dzisiejszych czasach każdy z Nas szuka rozwiązań ułatwiających codzienne funkcjonowanie , chociażby otwieranie bramy do swojego podwórka , dzięki Nam zycie stanie się jeszcze prostsze ,,automaty do bram” , od wyceny do realizacji . Nasz wykwalifikowany personel pomoże Państwu w doborze odpowiedniego automatu do bramy , wyceni system w ciągu 24 h i zamontuje w ciągu 14 dni od momentu przyjęcia zlecenia do realizacji . W XXI wieku wszyscy lubimy gadżety , otwieranie bramy z poziomu telefonu na pewno zainteresuje każdego miłośnika nowinek technicznych .<br> <ul><li>- montaż siłowników do bram przesuwnych </li><li>- montaż siłowników do bram dwuskrzydłowych  </li><li>- montaż szlabanów </li>'"
      ></single-oferta>
      <single-oferta
        :phone="users[2].phone"
        :mail="users[2].email"
        :id="'box6'"
        :photo="'alarm.png'"
        :heading="'Domofony , wideofony i systemy kontroli dostępu '"
        :headingBg="'Systemy'"
        :content="'<b>Kontrola dostępu</b> we współczesnych domach to obowiązkowy element wyposażenia, który ma za zadanie chronić mieszkańców przed nieproszonymi  gośćmi i sprawiać, że w swoim mieszkaniu czują się bezpiecznie i komfortowo.<br><br> <b>Wideofony</b> to kolejny krok w kierunku nowoczesności i rozbudowanie tradycyjnej funkcji komunikatora, jakim jest domofon, o funkcję wideo, z możliwością obserwowania przychodzącego gościa. Systemy kontroli dostępu pozwalają zabezpieczyć Nasze biura , firmy , magazyny itp. Dzięki tym rozwiązaniom możemy w łatwy sposób dać dostęp pracownikom do pomieszczeń firmowych ,bez potrzeby dorabiania dziesiątek kluczy . '"
      ></single-oferta>
      <single-oferta
        :phone="users[2].phone"
        :mail="users[2].email"
        :id="'box7'"
        :photo="'siec.png'"
        :heading="'Instalacje niskoprądowe'"
        :headingBg="'Niskoprądowe'"
        :content="'Domofony, systemy kontroli dostępu, kamery cyfrowe i analogowe wykorzystywane w monitoringu, sieci LAN i WLAN czy automatyka domowa najczęściej  wymagają stworzenia  odpowiedniej infrastruktury teletechnicznej czyli wykonania instalacji niskoprądowej. Zajmiemy  się projektem instalacji jak i jej wykonaniem . Po za wykonaniem instalacji oferujemy również klientowi szeroki asortyment osprzętu wymaganego do instalacji niskoprądowej .'"
      ></single-oferta>
    </div>
    <div class="navigation">
      <slot>
        <side-nav></side-nav>
      </slot>
    </div>
  </div>
</template>

<script>
import SingleOferta from './ofertaSection/ofertaBox.vue';
import SideNav from '../UI/sideNav.vue';
export default {
  components: {
    SingleOferta,
    SideNav,
  },
  data() {
    return {
      users: [
        {
          name: 'bulla',
          phone: '+48 660 920 940',
          email: 'mateusz@i-instal.pl',
        },
        {
          name: 'maks',
          phone: '+48 694 634 664',
          email: 'maksymilian@i-instal.pl',
        },
        {
          name: 'biuro',
          phone: '+48 660 920 940',
          email: 'biuro@i-instal.pl',
        },
      ],
    };
  },
  methods: {},
};
</script>

<style scoped>
.oferta__Section {
  top: 0;
  width: 100%;
  height: calc(100vh - 141px);
  transition: 0.5s all;
  color: white;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth !important;
  background: linear-gradient(107.56deg, #590f0f 0%, #1e25cb 100%);
}
@media screen and (max-width: 1180px) {
  .oferta__Section {
    height: calc(100vh - 104px);
  }
}
.navigation {
  position: fixed;
  z-index: 322;
}
@media screen and (max-width: 1180px) {
  .navigation {
    position: fixed;
    height: calc(100vh - 104px);
  }
}
</style>
